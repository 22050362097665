import { EditorState, SelectionState } from 'draft-js';
import { motion } from 'framer-motion';
import React, { SetStateAction } from 'react';
import { useRef } from 'react';
import CustomDialog from '../../shared/CustomDialog';
import AddToDictionaryModal from '../DictionaryModal/AddToDictionaryModal';
import { IDictionaryModalProps } from '../DictionaryModal/IDictionaryModal';
import EntriesModalContent from './EntriesModalContent';
import { IModal, IReplaceWordsModalProps, ModalVariantsEnums } from './IEditor';
import ReplaceWordsModalContent from './ReplaceWordsModalContent';
import SpeakersModalContent from './SpeakersModalContent';
import { ResizableBox } from 'react-resizable';
import { IEditorSpeaker } from '../../redux/store/IStore';
import SpeakerVectorsModal from './SpeakerVectorsModal';
import { useNavigate } from 'react-router-dom';

interface IProps {
  modal: IModal;
  setModal: React.Dispatch<SetStateAction<IModal>>;
  handleLeaveEditor: (variant: ModalVariantsEnums) => void;
  setEditorState: React.Dispatch<SetStateAction<EditorState>>;
  closeDictionaryModal: () => void;
  setSpeakerToSelection: (sel: SelectionState, speaker?: IEditorSpeaker) => void;
  replaceSpeaker: (newSpeaker: IEditorSpeaker, oldSpeaker: IEditorSpeaker, replaceAll: boolean, blockKey: string) => void;
  dictionaryModalProps: IDictionaryModalProps;
  replaceWordsModalProps: IReplaceWordsModalProps;
  editorState: EditorState;
}

const modalVariants = {
  visible: {
    transform: 'translate3d(0,0,100px)',
    zIndex: 100,
    opacity: 1,
    transition: { ease: 'easeInOut', duration: 0.2 },
  },
  hidden: {
    transform: 'translate3d(0,0,-1px)',
    zIndex: 100,
    opacity: 0,
    transition: { ease: 'easeInOut', duration: 0.2 },
  },
  containerHidden: {
    transform: 'scale(0.9)',
    transition: { ease: 'easeInOut', duration: 0.2 },
  },
  containerVisible: {
    transform: 'scale(1)',
    transition: { ease: 'easeInOut', duration: 0.2 },
  },
};

const ModalsWrapper = ({
  modal,
  setModal,
  setEditorState,
  handleLeaveEditor,
  closeDictionaryModal,
  replaceSpeaker,
  setSpeakerToSelection,
  replaceWordsModalProps,
  dictionaryModalProps,
  editorState,
}: IProps) => {
  const { variant, show } = modal;
  const modalRef = useRef<HTMLDivElement>(null);

  const closeSpeakerVectorModal = () => {
    setModal({ ...modal, show: false });
  }
  
  return (
    <motion.div
      className="modal_overlay"
      variants={modalVariants}
      initial="hidden"
      animate="visible"
      exit="hidden"
      onClick={(e: any) => {
        if (
          modalRef &&
          modalRef.current &&
          !modalRef.current.contains(e.target) &&
          modal.variant !== ModalVariantsEnums.ENTRIES
        ) {
          setModal({ ...modal, show: false });
        }
      }}
    >
      <motion.div
        className="modal_container"
        variants={modalVariants}
        initial="containerHidden"
        animate="containerVisible"
        exit="containerHidden"
        ref={modalRef}
      >
        {variant === ModalVariantsEnums.REPLACE_WORDS && (
          <CustomDialog>
            <ReplaceWordsModalContent
              replaceWordsModalProps={replaceWordsModalProps}
              editorState={editorState}
              setEditorState={setEditorState}
              setModal={setModal}
            />
          </CustomDialog>
        )}

        {variant === ModalVariantsEnums.SPEAKERS && (
          <CustomDialog>
            <SpeakersModalContent
              editorState={editorState}
              setEditorState={setEditorState}
              setModal={setModal}
              replaceSpeaker={replaceSpeaker}
              setSpeakerToSelection={setSpeakerToSelection}
            />
          </CustomDialog>
        )}

        {variant === ModalVariantsEnums.ENTRIES && (
          // <CustomDialog>
          <ResizableBox
            style={{ backgroundColor: '#f2f2f2', padding: '30px' }}
            width={560}
            height={660}
            minConstraints={[560, 660]}
            maxConstraints={[1600, 1600]}
          >
            <EntriesModalContent setModal={setModal} />
          </ResizableBox>
          // </CustomDialog>
        )}

        {variant === ModalVariantsEnums.DISCARD && (
          <CustomDialog
            message={
              // localStorage.getItem('EditorState')
              //   ? 'Zadnje verzije besedila niste shranili. Ali želite vseeno zapustiti urejevalnik?'
              variant === ModalVariantsEnums.DISCARD
                ? 'Ali ste prepričani, da želite zavreči zadnje spremembe in zapustiti urejevalnik?'
                : 'Ali ste prepričani, da zapustiti urejevalnik?'
            }
            onClickNo={() => setModal({ ...modal, show: false })}
            onClickYes={() => handleLeaveEditor(variant)}
            noButtonName="Prekliči"
            yesButtonName={'Zavrzi'}
          />
        )}

        {variant === ModalVariantsEnums.BLOCKS && (
          <CustomDialog
            message="Zaradi dolžine besedila je priporočljivo, da se ga razbije na bloke."
            onClickNo={() => {
              setModal({ ...modal, show: false });
            }}
            onClickYes={() => {
              setModal({ ...modal, show: false });
            }}
            noButtonName="Prekliči"
            yesButtonName="Strinjam se"
          />
        )}

        {variant === ModalVariantsEnums.ADD_TO_DICT && (
          <AddToDictionaryModal
            onClose={closeDictionaryModal}
            opened={dictionaryModalProps.open}
            word={dictionaryModalProps.word}
          />
        )}

        {variant === ModalVariantsEnums.SPEAKER_VECTORS && (
          <SpeakerVectorsModal onClose={closeSpeakerVectorModal}/>
        )}
      </motion.div>
    </motion.div>
  );
};

export default ModalsWrapper;
