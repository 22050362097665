import React, { useEffect, useState } from 'react';
import './option_selector.css';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Zoom from '@mui/material/Zoom';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';

interface IDropdownArrow {
    fill?: string;
}
const DropwdownArrow = ({
    fill
}: IDropdownArrow) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 10L12 15L17 10H7Z" fill={fill ? fill : "#707070"} />
        </svg>
    )
}
interface IOptionSelector {
    title: string;
    options: any[];
    value: any;
    updateValue: (value: any) => void;
    extractValues: (value: any) => string;
    handleOpened: (value: boolean) => void;
    opened: boolean;
    disabled?: boolean;
}

const OptionSelector = ({
    title,
    value,
    opened,
    options,
    disabled,
    updateValue,
    extractValues,
    handleOpened,
}: IOptionSelector) => {
    const [anchorElAddLabel, setAnchorElAddLabel] = useState<HTMLElement | null>(null);
    const addButtonId = opened ? 'simple-popper' : undefined;

    const clickAwayHandler = () => {
        if (anchorElAddLabel) {
            handleOpened(false);
            setAnchorElAddLabel(null);
        }
    };

    const openOptions = (event: React.MouseEvent<HTMLElement>) => {
        if (!anchorElAddLabel) {
            const target = event.currentTarget;
            setAnchorElAddLabel(target);
            handleOpened(true);
        } else {
            setAnchorElAddLabel(null);
            handleOpened(false);
        }
    }

    const updateValueWrapper = (newValue: any) => {
        updateValue(newValue)
        setAnchorElAddLabel(null);
        handleOpened(false);
    }
    
    let valueClass = 'option_selector_value'
    if (value && options.filter(option => extractValues(option) === extractValues(value)).length === 0) {
        valueClass = 'option_selector_value_error'
    }
    
    let containerClass = "option_selector_container"
    let selectorClass = "option_selector_value_container"
    if (disabled) {
        containerClass = "option_selector_container_disabled"
        selectorClass = "option_selector_value_container_disabled"
    }

    let selector = ""
    if (title === "Jezik") {
        selector = "languageSelector"
    } else if (title === "Domena") {
        selector = "domainSelector"
    }

    return (
        <>
            <div className='option_selector_wrapper'>
                <div className={containerClass}>
                    <p className='option_selector_title'>{title}</p>
                    <div data-testid={selector} onClick={openOptions} className={selectorClass}>
                        <p className={valueClass}>{value ? extractValues(value) : ''}</p>
                        <DropwdownArrow />
                    </div>
                </div>
                {anchorElAddLabel && (
                    <Popper
                        className='popper'
                        id={addButtonId}
                        open={opened}
                        anchorEl={anchorElAddLabel}
                        disablePortal={false}
                        style={{ zIndex: 100000, width: anchorElAddLabel?.clientWidth }}
                        transition
                    >
                        {({ TransitionProps }) => (
                            <ClickAwayListener onClickAway={clickAwayHandler}>
                                <Zoom {...TransitionProps} timeout={200}>
                                    <Paper
                                        elevation={3}
                                        className="accordion-chip-popper-container"
                                        style={{ marginLeft: '-5px' }}
                                        square={true}
                                    >
                                        {options.map((option, index) => (
                                                <MenuItem className='settings-menu-item' data-testid={extractValues(option)} key={`${title}-${index}`} onClick={() => updateValueWrapper(option)}>
                                                    {extractValues(option)}
                                                </MenuItem>
                                            ))}
                                    </Paper>
                                </Zoom>
                            </ClickAwayListener>
                        )}
                    </Popper>
                )}
            </div>
        </>
    )
}

export default OptionSelector;