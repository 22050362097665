import React from 'react';
import { IEditorSpeaker } from '../../redux/store/IStore';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';

export default function SpeakerSection({
  speaker,
  onClick,
  isFirst,
}: {
  speaker: IEditorSpeaker | null;
  onClick?: (speaker: IEditorSpeaker) => void;
  isFirst: boolean;
}) {
  if (speaker === null) return <></>;

  const { name, selectedRole } = speaker;

  return (
    <div
      contentEditable={false}
      style={{
        userSelect: 'none',
        width: 'fit-content',
        marginBottom: 10,
        marginTop: isFirst ? 0 : 30,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <RecordVoiceOverIcon sx={{color: "#2b8dff", marginRight: "8px"}}/>
      <span
        contentEditable={false}
        className={`speaker_button${onClick ? '': 'speaker_button_unclickable'}`}
        style={{ borderColor: `#2b8dff` }}
        onClick={() => (onClick ? onClick(speaker) : () => {})}
      >
        {name}{selectedRole ? ` | ${selectedRole}` : ""}
      </span>
    </div>
  );
}
