import React, { ChangeEvent, useState, MouseEvent } from "react";
import { ISpeakerRole } from "./types";
import { useAppDispatch } from "../../../redux/store";
import { cloneDeep } from "lodash";
import { SpeakerModalStage } from "../../../redux/store/IStore";

import { addSpeaker } from "../../../api/speakersService";
import Add from "@mui/icons-material/Add";
import CloseIcon from '@mui/icons-material/Close';
import { updateSpeakerModal } from "../../../redux/features/app/app";

const SpeakerModalCreate = () => {
    const dispatch = useAppDispatch();

    const [speakerLabel, setSpeakerLabel] = useState<string>('');
    const [speakerRole, setSpeakerRole] = useState<string>('');
    const [speakerRoles, setSpeakerRoles] = useState<ISpeakerRole[]>([]);

    const updateSpeakerLabel = (event: ChangeEvent<HTMLInputElement>) => setSpeakerLabel(event.target.value)
    const updateSpeakerRole = (event: ChangeEvent<HTMLInputElement>) => setSpeakerRole(event.target.value)

    const addSpeakerRole = () => setSpeakerRoles(current => {
        if (speakerRole.length < 1) return current;

        current.push({ label: speakerRole, default: current.length === 0 })
        setSpeakerRole("");

        return current;
    })

    const changeDefaultRole = (index: number) => {
        const copy = cloneDeep(speakerRoles);
        copy.forEach((role, iterationIndex) => role.default = iterationIndex === index)
        setSpeakerRoles(copy)
    }

    const deleteRole = (event: MouseEvent<HTMLButtonElement>, index: number) => {
        event.preventDefault();
        event.stopPropagation();
        const copy = cloneDeep(speakerRoles);
        const isDeletedDefault = copy[index].default

        copy.splice(index, 1);
        if (isDeletedDefault && copy.length > 0) {
            copy[0].default = true
        }

        setSpeakerRoles(copy)
    }

    const cancelSpeakerCreate = () => dispatch(updateSpeakerModal({ modalStage: SpeakerModalStage.list }))

    const addSpeakerWrapper = async () => {
        const roles = speakerRoles.sort((a, b) => Number(b.default) - Number(a.default)).map(role => role.label)
        try {
            await addSpeaker({
                name: speakerLabel,
                roles,
                updateLastSelectedAt: false,
            });
            dispatch(updateSpeakerModal({ modalStage: SpeakerModalStage.list }))
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <div>
                <div id="speaker_modal_header_wrapper">
                    <p id="speaker_modal_header_title">Dodaj govorca</p>
                </div>
                <input className="add_user_input" value={speakerLabel} placeholder="Vnesite ime..." onChange={updateSpeakerLabel} />
                <div id="speaker_modal_role_input_container">
                    <input className="add_user_input" id="speaker_modal_role_input" value={speakerRole} placeholder="Vnesite vlogo..." onChange={updateSpeakerRole} />
                    <button onClick={addSpeakerRole}>
                        <Add sx={{ color: "#2b8dff" }} />
                    </button>
                </div>
                <div id="speaker_roles_container">
                    {speakerRoles.map((iterationRole, index) => {
                        return (
                            <button onClick={() => changeDefaultRole(index)} className={`speaker_role${iterationRole.default ? " speaker_role_default" : ""}`}>
                                <button onClick={event => deleteRole(event, index)} className="speaker_role_delete">
                                    <div className={`speaker_role_delete_container${iterationRole.default ? " speaker_role_delete_container_default" : ""}`}>
                                        <CloseIcon sx={{ color: iterationRole.default ? "#2b8dff" : "#F2F2F2", width: 18, height: 18 }} />
                                    </div>
                                </button>
                                <p>{iterationRole.label}</p>
                            </button>
                        )
                    })}
                </div>
            </div>
            <div id="speaker_modal_footer">
                <button id="spekaer_modal_close_button" onClick={cancelSpeakerCreate}>NAZAJ</button>
                <button id="spekaer_modal_save_button" onClick={addSpeakerWrapper}>DODAJ</button>
            </div>
        </div>
    )
}

export default SpeakerModalCreate;