import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import useEditor from '../../hooks/useEditor';
import { cloneDeep } from 'lodash';
import { ISegment, updateSpeakerVector } from '../../api/speakersService';
import { patchEditorContentV30, unlockSession } from '../../api/SessionsService';
import { convertToRaw } from 'draft-js';
import { setHomeFlowState } from '../../redux/features/app/app';
import './speaker_vectors_modal.css';
import { Duration } from "luxon";
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { enqueueSnackbar } from 'notistack';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SquareIcon from '@mui/icons-material/Square';
import DisabledByDefaultRounded from '@mui/icons-material/DisabledByDefaultRounded';

function formatDuration(ms: number): string {
  const duration = Duration.fromMillis(ms).shiftTo("hours", "minutes", "seconds", "milliseconds");

  const hours = duration.hours;
  const minutes = duration.minutes;
  const seconds = Math.floor(duration.seconds);
  const milliseconds = Math.floor(duration.milliseconds / 100); // Get tenths of a second

  let formatted = "";

  if (hours > 0) {
    formatted += `${String(hours).padStart(2, "0")}:`;
  }
  if (minutes > 0 || hours > 0) {
    formatted += `${String(minutes).padStart(2, "0")}:`;
  }
  
  formatted += `${String(seconds).padStart(2, "0")}.${milliseconds}`;

  return formatted;
}

enum UpdateSpeakerStatus {
    UPDATED = "UPDATED",
    UPDATING = "UPDATING",
    QUEUED = "QUEUED",
    IDLE = "IDLE",
    ERROR = "ERROR"
}
interface IUpdateSpeaker {
    id: number;
    name: string;
    selected: boolean;
    segments: ISegment[];
    updateSpeakerStatus: UpdateSpeakerStatus
    
}
interface ISpeakerVectorsModalProps {
    onClose: () => void;
}
const SpeakerVectorsModal = ({
    onClose
}: ISpeakerVectorsModalProps) => {
    const dispatch = useAppDispatch();
    const { editorState } = useEditor();
    const navigate = useNavigate();


    const [speakersToUpdate, setSpeakersToUpdate] = useState<IUpdateSpeaker[]>([])
    const editorLock = useAppSelector(store => store.app.editorLock)
    const endSpeakerList = useAppSelector(store => store.app.endSpeakerList)
    const startSpeakerList = useAppSelector(store => store.app.startSpeakerList)
    const [anaWorks, setAnaWorks] = useState<boolean>(true);
    const [processingVectors, setProcessingVectors] = useState<boolean>(false);


    const updateWrapper = useCallback(async () => {
        if (!editorLock.sessionId) return;

        setProcessingVectors(true);

        const speakersListToUpdate = speakersToUpdate.filter(speaker => speaker.selected && speaker.updateSpeakerStatus !== UpdateSpeakerStatus.UPDATED).map(speaker => {
            return { id: speaker.id, segments: speaker.segments, sessionId: editorLock.sessionId as number }
        })



        let atLeastOneError = false;

        const speakersToUpdateCopy = cloneDeep(speakersToUpdate)

        speakersToUpdateCopy.forEach(speaker => {
            speaker.updateSpeakerStatus = speakersListToUpdate.filter(filterSpeaker => filterSpeaker.id === speaker.id).length === 1 ? UpdateSpeakerStatus.QUEUED : speaker.updateSpeakerStatus
        })
        setSpeakersToUpdate(() => speakersToUpdateCopy)

        for (let speakerIndex = 0; speakerIndex < speakersListToUpdate.length; speakerIndex++) {
            const speaker = speakersListToUpdate[speakerIndex];
            
            setSpeakersToUpdate(currentSpeakers => {
                const currentSpeakersCopy = cloneDeep(currentSpeakers)
                currentSpeakersCopy.forEach(iterationSpeaker => {
                    if (iterationSpeaker.id === speaker.id) {
                        iterationSpeaker.updateSpeakerStatus = UpdateSpeakerStatus.UPDATING
                    }
                })
                return currentSpeakersCopy;
            })

            try {
                if (!anaWorks && speaker.id === 4) throw new Error();
                await updateSpeakerVector(speaker.id, speaker.sessionId, speaker.segments)
                setSpeakersToUpdate(currentSpeakers => {
                    const currentSpeakersCopy = cloneDeep(currentSpeakers)
                    currentSpeakersCopy.forEach(iterationSpeaker => {
                        if (iterationSpeaker.id === speaker.id) {
                            iterationSpeaker.updateSpeakerStatus = UpdateSpeakerStatus.UPDATED
                        }
                    })
                    return currentSpeakersCopy;
                })
            } catch (error) {
                console.log(error);
                atLeastOneError = true;
                
                setSpeakersToUpdate(currentSpeakers => {
                    const currentSpeakersCopy = cloneDeep(currentSpeakers)
                    currentSpeakersCopy.forEach(iterationSpeaker => {
                        if (iterationSpeaker.id === speaker.id) {
                            enqueueSnackbar(`Pri posodabljanju govorca ${iterationSpeaker.name} je prišlo do napake.`, { variant: 'error' })
                            iterationSpeaker.updateSpeakerStatus = UpdateSpeakerStatus.ERROR
                        }
                    })
                    return currentSpeakersCopy;
                })
            }
        }

        //If one speaker was in error, do not return but leave the user on screen.
        if (atLeastOneError) {
            setProcessingVectors(false);
            return;
        }

        try {
            if (editorState && editorLock && editorLock.sessionLockKey && editorLock.editTicket && editorLock.sessionId) {
                await patchEditorContentV30(
                    { rawContentState: convertToRaw(editorState.getCurrentContent()) },
                    editorLock.sessionId,
                    editorLock.editTicket,
                    editorLock.sessionLockKey,
                );

                await unlockSession(editorLock.sessionId, editorLock.sessionLockKey)
            }
            dispatch(setHomeFlowState({ liveFlowInProgress: false }));
            onClose();
            navigate('/dashboard')
        } catch (error) {
            console.log(error);
        }
    }, [speakersToUpdate, anaWorks]) 

    const toggleSpeaker = (id: number) => {
        const copy = cloneDeep(speakersToUpdate);
        copy.forEach(speaker => {
            if (speaker.id === id) {
                speaker.selected = !speaker.selected
            }
        })
        setSpeakersToUpdate(copy)
    }

    const closeWrapper = () => {
        dispatch(setHomeFlowState({ liveFlowInProgress: false }));
        onClose();
        navigate('/dashboard')
    }

    useEffect(() => {
        const oldTimesMap = new Map<number, { startTime: number, endTime: number }[]>();

        startSpeakerList.forEach(item => {
          const times = oldTimesMap.get(item.speaker.id) || [];
          times.push({ startTime: item.startTime, endTime: item.endTime });
          oldTimesMap.set(item.speaker.id, times);
        });
      
        // Find new times that are not in old times or overlapping
        const speakerMap = new Map<number, IUpdateSpeaker>();
      
        endSpeakerList.forEach(newItem => {
          const oldTimes = oldTimesMap.get(newItem.speaker.id) || [];
      
          // Check if there is any overlap with old times
          const isOverlapping = oldTimes.some(oldTime => newItem.startTime < oldTime.endTime && newItem.endTime > oldTime.startTime);
      
          if (!isOverlapping) {
            if (!speakerMap.has(newItem.speaker.id)) {
              speakerMap.set(newItem.speaker.id, {
                id: newItem.speaker.id,
                name: newItem.speaker.name,
                selected: false,
                segments: [],
                updateSpeakerStatus: UpdateSpeakerStatus.IDLE
              });
            }
      
            speakerMap.get(newItem.speaker.id)!.segments.push({
              startTimeMs: Math.floor(newItem.startTime * 1000),
              endTimeMs: Math.floor(newItem.endTime * 1000)
            });
          }
        });

        setSpeakersToUpdate(Array.from(speakerMap.values()))        
    }, [])

    return (
        <div style={{ backgroundColor: "#F2F2F2", padding: "12px 24px", width: '25vw' }}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 16}}>
                <h1 id="speakers_vector_title">Posodobitev vektorjev</h1>
                <button disabled={processingVectors} onClick={onClose}>
                    <CloseIcon />
                </button>
            </div>

            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <p>Ana works: </p>
                <input onChange={() => setAnaWorks(!anaWorks)} type="checkbox" id={"Ana works"} name={"Ana works"} checked={anaWorks} />
            </div>
            <div id="table-container-speaker">
            <table id="speaker-vector-table">
                <thead id="speaker-table-head">
                    <tr>
                        <th className='column-header-speaker' style={{ textAlign: "left" }}>Status</th>
                        <th className='column-header-speaker' style={{ textAlign: "left" }}>Govorec</th>
                        <th className='column-header-speaker' style={{ textAlign: "left" }}>Dolžina</th>
                        <th className='column-header-speaker' style={{ textAlign: "right" }}>Posodobi</th>
                    </tr>
                </thead>
                <tbody style={{maxHeight: 400, overflowY: 'scroll'}}>
                    <tr style={{height: 8}} />
                    {speakersToUpdate.filter(speaker => speaker.id > -1).map((speaker, index) => {
                        const disabled = speaker.updateSpeakerStatus === UpdateSpeakerStatus.UPDATING || speaker.updateSpeakerStatus === UpdateSpeakerStatus.QUEUED || speaker.updateSpeakerStatus === UpdateSpeakerStatus.UPDATED
                        return (
                            <>
                            <tr style={{height: 2}} />
                            <tr key={speaker.id} style={{marginTop: 16}}>
                                <td style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                                    {speaker.updateSpeakerStatus === UpdateSpeakerStatus.QUEUED && <div className='loader-speaker' /> }
                                    {speaker.updateSpeakerStatus === UpdateSpeakerStatus.UPDATING && <div className='loader-speaker' />}
                                    {speaker.updateSpeakerStatus === UpdateSpeakerStatus.UPDATED && <CheckBoxIcon sx={{color: '#4BB543'}} />}
                                    {speaker.updateSpeakerStatus === UpdateSpeakerStatus.IDLE && <SquareIcon className='cirle_loader' sx={{color: '#f2f2f2'}} />}
                                    {speaker.updateSpeakerStatus === UpdateSpeakerStatus.ERROR && <DisabledByDefaultRounded sx={{color: '#d32f2e'}} />}
                                </td>
                                <td style={{ textAlign: "left" }}>{speaker.name}</td>
                                <td>{formatDuration(speaker.segments.reduce((sum, segment) => sum + (segment.endTimeMs - segment.startTimeMs), 0))}</td>
                                <td style={{ textAlign: "right" }}>
                                    <input style={{margin: 0}} disabled={disabled} onChange={() => toggleSpeaker(speaker.id)} type="checkbox" id={speaker.name} name={speaker.name} checked={speaker.selected} />
                                </td>
                            </tr>
                            </>
                        )
                    })}
                </tbody>
            </table>
            </div>

            <div style={{marginTop: 42, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                <button disabled={processingVectors} id="close_button" onClick={closeWrapper}>ZAPRI</button>
                <button disabled={processingVectors || speakersToUpdate.filter(speaker => speaker.selected && (speaker.updateSpeakerStatus !== UpdateSpeakerStatus.UPDATED)).length < 1} id="end_button" onClick={updateWrapper}>POSODOBI</button>
            </div>
        </div>
    )
}

export default SpeakerVectorsModal





