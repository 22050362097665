import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import SettingsDrawer from './SettingsDrawer';
import FooterInfo from './FooterInfo';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { AnimButtonStatesEnum, ConsumtionModalTemplate, EditorModeEnums, IStore, UserRoleEnums } from '../../redux/store/IStore';
import separateExtension from '../../shared/separateExtension';
import '../../styles/css/home.css';
import { readFile } from '../../shared/readFile';
import { useSnackbar } from 'notistack';
import { processFile } from '../../api/uploadService';
import useV3Ws from '../../hooks/useV3Ws';
import useKeycloak from '../../hooks/useKeycloak';
import usePipeline from '../../hooks/usePipeline';
import CircularProgress from '@mui/material/CircularProgress';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { setActiveQuota, setAnimButtonState, setAudioInfo, setConsumptionModal, setEditorMode, setHomeFlowState, setIsDashboardReady, setLoadContentToEditor, setSessionName, setSpeakerSettingsModal, setTbFormat, setValidRedirect, updateDashboardRefresh } from '../../redux/features/app/app';
import { getActiveQuota, getUserQuota } from '../../api/UserService';
import SpeakerSettingsModal from '../SpeakerSettings/SpeakerSettingsModal';
import WarnMessageContent from '../CustomNotistack/WarnMessageContent';

enum FileExtensions {
  WAV = 'wav',
  TB = 'tb',
}

const Home: FC = () => {
  const [isMounted, setIsMounted] = useState(false);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const user = useAppSelector(state => state.app.user);
  const fontsLoaded = useAppSelector(state => state.app.fontsLoaded);
  const liveFlowInProgress = useAppSelector(state => state.app.homeFlowStates.liveFlowInProgress);
  const uploadFlowInProgress = useAppSelector(state => state.app.homeFlowStates.uploadFlowInProgress);
  const speakerSettingsModal = useAppSelector((state) => state.app.speakerSettingsModal)

  const navigate = useNavigate();
  const fileUploadRef = React.useRef<HTMLInputElement>(null);
  const progressRef = React.useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { buildPipeline } = usePipeline();
  
  const { startRecording } = useV3Ws();
  const { isAuthenticated } = useKeycloak();
  
  const activeConfiguration = useAppSelector(store => store.app.activeConfiguration)
  const activeQuota = useAppSelector(state => state.app.activeQuota);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (!isMounted) return;
    if (!user) {
      setShowSettings(true);
    }
  }, [user, isMounted]);

  const handleNotAuthFlow = () => {
    setShowSettings(true);

    enqueueSnackbar('Za uporabo storitve se prijavite.', { variant: 'error' });
  };

  const handleLiveClick = useCallback(async () => {
    if (liveFlowInProgress || uploadFlowInProgress) return;
    dispatch(setHomeFlowState({ liveFlowInProgress: true }));
    

    if (!isAuthenticated || !user) {
      handleNotAuthFlow();
      dispatch(setHomeFlowState({ liveFlowInProgress: false }));
      return;
    }

    if (!user.userRoles.includes(UserRoleEnums.PIPELINE_ONLINE_API)) {
      enqueueSnackbar('Za transkribiranje v živo nimate ustreznih pravic.', { variant: 'error' });
      dispatch(setHomeFlowState({ liveFlowInProgress: false }));
      return;
    }

    if (activeConfiguration.model && !activeConfiguration.model.onlineAsr) { 
      enqueueSnackbar(`Izbran model ne omogoča transkribiranja v živo.`, {
        variant: 'error',
        autoHideDuration: 4000,
      });
      dispatch(setHomeFlowState({ liveFlowInProgress: false }));
      return;
    }


    dispatch(setHomeFlowState({ liveFlowInProgress: true }));
    

    dispatch(setAnimButtonState(AnimButtonStatesEnum.RECORDING));
    dispatch(setEditorMode(EditorModeEnums.RECORDING_MODE));
    
    dispatch(setValidRedirect(true));
    dispatch(
      setLoadContentToEditor({
        recFinishedStartLoadingNewEditorState: false,
        recStartedLoadTextFromEditor: true,
      })
    );

    const pipeline = buildPipeline()

    if (!pipeline) {
      enqueueSnackbar('Operacija ni možna, ker ni izbran noben model. Preverite nastavitve.', { variant: 'error' });
      dispatch(setHomeFlowState({ liveFlowInProgress: false }));
      return;
    }
    
    await startRecording(pipeline, null)
    dispatch(setIsDashboardReady(false))
    navigate('/editor')

    
  }, [activeConfiguration, buildPipeline]);

  const updateProgress = (value: number) => {
    if (progressRef.current) {
      progressRef.current.style.width = 383 * value + "px";
    }
  }
  const handleFileChange = useCallback(async (event: any) => {
    dispatch(setHomeFlowState({ uploadFlowInProgress: true }));
    if (!isAuthenticated || !user) {
      handleNotAuthFlow();
      dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
      return;
    }

    console.log(user.userRoles)
    if (!user.userRoles.includes(UserRoleEnums.PIPELINE_OFFLINE_API)) {
      enqueueSnackbar('Za odloženo transkribiranje nimate ustreznih pravic.', { variant: 'error' });
      dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
      return;
    }

    const files: FileList = event.target.files;

    let tbIndex: number | null = null;
    let wavIndex: number | null = null;

    // Check for existence of files
    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      if (file) {
        //@ts-ignore
        const [fileName, fileExtension] = separateExtension(file.name);

        // Found a .tb file
        if (fileExtension === FileExtensions.TB) {
          // If it has not yet been found its ok
          if (tbIndex === null) {
            tbIndex = i;
          }

          // If it has already been found its a duplicate
          else {
            enqueueSnackbar('Naložite lahko samo eno tb datoteko na enkrat.', { variant: 'error' });
            dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
            return;
          }
        }

        // Found a .wav file
        if (fileExtension === FileExtensions.WAV) {
          // If it has not yet been found its ok
          if (wavIndex === null) {
            wavIndex = i;
          }

          // If it has already been found its a duplicate
          else {
            enqueueSnackbar('Naložite lahko samo eno wav datoteko na enkrat.', { variant: 'error' });
            dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
            return;
          }
        }
      } else {
        dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
      }
    }

    // Error
    if (tbIndex !== null && wavIndex === null) {
      enqueueSnackbar('Naložiti morate tb in wav datoteko.', { variant: 'error' });
      dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
      return;
    }

    // Flow for only wav
    else if (tbIndex === null) {
      //@ts-ignore
      const file = files.item(wavIndex);
      if (file) {
        //@ts-ignore


        const duration = file.size / (16000 * 1 * 2)
        if (
          activeQuota &&
          activeQuota.chunk.secondsLimit &&
          activeQuota.chunk.secondsLimit - activeQuota.chunk.secondsConsumed < duration
        ) {
          //Alert user
          enqueueSnackbar('Posnetek presega vašo razpoložljivo kvoto.', { variant: 'error' });
          dispatch(setConsumptionModal({
            visible: true,
            template: ConsumtionModalTemplate.UPLOAD_LIMIT
          }))
          dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
          return;
        }

        if (activeQuota && activeQuota.chunk.secondsWarn && activeQuota.chunk.secondsWarn <= activeQuota.chunk.secondsConsumed + duration) {
          enqueueSnackbar(<WarnMessageContent />, {variant: "warn"})
        }
        
        const [fileName, fileExtension] = separateExtension(file.name);
        dispatch(setSessionName(fileName));

        let loadedWavFile: any;

        try {
          loadedWavFile = await readFile(file);
        } catch (err) {
          console.log(err);

          enqueueSnackbar('Zvočnega posnetka ni mogoče prebrati.', {
            variant: 'error',
          });
          dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
          return;
        }
        
        const pipeline = buildPipeline();
        if (!pipeline) {
          enqueueSnackbar('Operacija ni možna, ker ni izbran noben model. Preverite nastavitve.', { variant: 'error' });
          dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
          return;
        }

        try {
          await processFile(file, pipeline, updateProgress)
          dispatch(updateDashboardRefresh(true));
        } catch (error) {
          const mappedError = error as any;
          if (mappedError.response.status === 400) {
            enqueueSnackbar(`Prišlo je do napake. Prosimo kontaktirajte tehnično podporo. Koda napake: ${mappedError.response.data.id}`, { variant: 'autoCopyError', autoHideDuration: null })
          } else if (mappedError.response.status === 403) {
            enqueueSnackbar("Za delo s slovarjem nimate ustreznih pravic", { variant: 'error' })
          } else if (mappedError.response.status === 423) {
            enqueueSnackbar("Porabili ste vso razpoložljivo kvoto.", { variant: 'error' })
          } else if (mappedError.response.status === 429) {
            enqueueSnackbar("Dosegli ste največje število vzporednih sej.", { variant: 'error' })
          }
          updateProgress(0)
          dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
          return;
        }

        try {
          const quota = await getUserQuota()
          dispatch(setActiveQuota(getActiveQuota(quota.data)))
        } catch (error) {
          console.log(error);
        }

        updateProgress(0)
        dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
        navigate('/dashboard')
      } else {
        dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
      }
    }

    // Flow for both .tb and .wav
    else if (wavIndex !== null && tbIndex !== null) {
      //@ts-ignore
      const tbFile = files.item(tbIndex);
      //@ts-ignore
      const wavFile = files.item(wavIndex);

      let tbFinished = false;
      let wavFinished = false;

      if (tbFile && wavFile) {
        //@ts-ignore
        const [fileName, fileExtension] = separateExtension(wavFile.name);
        const tbReader = new FileReader();
        tbReader.onload = async (e: any) => {
          tbFinished = true;
          let tbFormat: any = JSON.parse(e.target.result);

          if (tbFormat.transcript && tbFormat.transcript[0] && tbFormat.transcript[0].text) {
            tbFormat = { ...tbFormat, transcript: [[...tbFormat.transcript]] };
          } else if (tbFormat.blocks) {
            tbFormat = { editorStateStringified: JSON.stringify(tbFormat) };
          }
          dispatch(setTbFormat(tbFormat));
          dispatch(setValidRedirect(true));
          dispatch(setEditorMode(EditorModeEnums.TB_UPLOAD_MODE));
          dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
          try {
            const quota = await getUserQuota()
            dispatch(setActiveQuota(getActiveQuota(quota.data)))
          } catch (error) {
            console.log(error)
          }
          wavFinished && navigate('/editor');
        };

        //@ts-ignore
        tbReader.readAsText(tbFile);

        const wavReader = new FileReader();
        wavReader.onload = async (e: any) => {
          wavFinished = true;
          dispatch(setSessionName(fileName));
          dispatch(setAudioInfo({ url: e.target.result, loadNew: true }));
          dispatch(setValidRedirect(true));
          dispatch(setEditorMode(EditorModeEnums.TB_UPLOAD_MODE));
          dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
          try {
            const quota = await getUserQuota()
            dispatch(setActiveQuota(getActiveQuota(quota.data)))
          } catch (error) {
            console.log(error)
          }
          tbFinished && navigate('/editor');
        };
        //@ts-ignore
        wavReader.readAsDataURL(wavFile);
      } else {
        dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
      }
    }
  }, [user, activeConfiguration, buildPipeline]);

  const handleClickSettings = () => {
    setShowSettings(true);
  };

  const handleCloseSettings = () => {
    setShowSettings(false);
  };

  const handleOnHistoryClick = () => {
    if (uploadFlowInProgress || liveFlowInProgress) return;
    if (!isAuthenticated) {
      handleNotAuthFlow();
      return;
    }

    navigate('/dashboard');
  };

  const checkIfCanceledInput = () => {
    if (!fileUploadRef.current) throw new Error('focus should not trigger when fileUploadRef is null!');

    //it is always empty
    if (fileUploadRef.current.value === '') {
      dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
      window.removeEventListener('focus', checkIfCanceledInput);
    }
  };

  let areAllSecondsConsumedOrNotLoaded = false
  if (activeQuota && activeQuota.chunk.secondsLimit && activeQuota.chunk.secondsConsumed >= activeQuota.chunk.secondsLimit) {
    areAllSecondsConsumedOrNotLoaded = true
  }

  //const isConfigSelected = activeConfiguration.serviceProvider && activeConfiguration.language && activeConfiguration.domain && activeConfiguration.model
  const disabledLive = uploadFlowInProgress || liveFlowInProgress || /*!isConfigSelected||*/  areAllSecondsConsumedOrNotLoaded || (user && !user.isEditorUser) || !user || (activeConfiguration.model && !activeConfiguration.model.onlineAsr) || (activeConfiguration.model && activeConfiguration.model.enableSd && activeConfiguration.model.enableSd.value) || !user.userRoles.includes(UserRoleEnums.PIPELINE_ONLINE_API);
  const disabledUpload = uploadFlowInProgress || liveFlowInProgress || /*!isConfigSelected||*/  areAllSecondsConsumedOrNotLoaded || (user && !user.isEditorUser) || !user || !user.userRoles.includes(UserRoleEnums.PIPELINE_OFFLINE_API);

  const handleAfterSpeakerSettingsClick = () => {
    if (!isAuthenticated) {
      handleNotAuthFlow();
      dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
      return;
    }

    if (!user?.userRoles.includes(UserRoleEnums.PIPELINE_OFFLINE_API)) {
      enqueueSnackbar('Za odloženo transkribiranje nimate ustreznih pravic.', {
        variant: 'error',
      });
      dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
      return;
    }

    if (!fileUploadRef.current) {
      dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
      return;
    }
    
    window.addEventListener('focus', checkIfCanceledInput);
    fileUploadRef.current.value = '';
    fileUploadRef.current.click();
  }


  const handleUploadLabelClick = useCallback(async (event: any) => {
    event.preventDefault();
    if (uploadFlowInProgress || liveFlowInProgress) return;
    dispatch(setHomeFlowState({ uploadFlowInProgress: true }));


    if (activeConfiguration.model && activeConfiguration.model.enableSd && activeConfiguration.model.enableSd.value) {
      dispatch(setSpeakerSettingsModal({visible: true}));
      return;
    }
    //check here if diarization is enabled
    handleAfterSpeakerSettingsClick();
  }, [user, activeConfiguration]);

  const handleCloseSpeakerSettingsModal = () => {
    dispatch(setHomeFlowState({ uploadFlowInProgress: false }));
  }


  const initialRef = useRef<boolean>(true);
  useEffect(() => {
    if (initialRef.current) {
      initialRef.current = false;
      return;
    }
    if (!speakerSettingsModal.visible && uploadFlowInProgress) handleAfterSpeakerSettingsClick();
  }, [speakerSettingsModal])
  return (
    <>
      {/*<div id="test_click" style={{backgroundColor: '#FF000061'}}>
        <input ref={fileUploadRef} onChange={handleFileChange} type="file" id="fileElem" accept=".wav" style={{display: "none"}} />
        <button onClick={handleUploadLabelClick} id="fileSelect" style={{width: '100%', height: "50px"}}></button>
  </div>*/}
      <div className="home">
        <SettingsDrawer handleCloseSettings={handleCloseSettings} show={showSettings} />
        {fontsLoaded && (
          <div
            className="buttons_container"
          >
            <div className="live_settings_container">
              <div className="live_settings_buttons">
              <button
                  data-testid={disabledLive ? 'disabled_live' : 'button_live'}
                  className={disabledLive ? 'disabled_live' : 'button_live'}
                  onClick={disabledLive ? () => {} : handleLiveClick}
                  style={{
                    cursor: disabledLive ? 'default' : 'pointer',
                    // opacity: disabledLive ? 0.8 : 1,
                    ...(disabledLive ? { backgroundColor: '#CCCCCC' } : {}),
                  }}
                >
                  <div className='button_live_animating_square'/>
                  {liveFlowInProgress ? <CircularProgress color="inherit" /> : <span>V ŽIVO</span>}
                </button>
                <button
                  disabled={disabledUpload}
                  onClick={disabledUpload ? () => {} : handleUploadLabelClick}
                  //htmlFor={authenticated ? "button-file" : undefined}
                  className={disabledUpload ? "upload_disabled" : "button_upload"}
                  id="fileSelect"
                >
                  <div className='upload_button_animating_square'/>
                  <div className="upload_tittle_container">
                    <span>NALOŽI</span>
                  </div>
                  <div id="progress" ref={progressRef} />

                  {/*<button id="fileSelect" style={{ display: 'none' }} />*/}
                </button>
                <input
                  ref={fileUploadRef}
                  onChange={handleFileChange}
                  type="file"
                  multiple
                  id="fileElem"
                  // accept=".wav" we should accept any extensions
                  style={{ display: 'none' }}
                />
              </div>
              <button
                data-testid="open_history_button"
                className="button_history"
                onClick={handleOnHistoryClick}
                disabled={uploadFlowInProgress || liveFlowInProgress}
                style={{
                  cursor: uploadFlowInProgress || liveFlowInProgress ? 'default' : 'pointer',
                  opacity: uploadFlowInProgress || liveFlowInProgress ? 0.8 : 1,
                }}
              >
                ZGODOVINA
              </button>
            </div>
          </div>
        )}
        {fontsLoaded && (
          <div
            style={{
              position: 'absolute',
              right: '25px',
              bottom: '25px',
              left: 0,
              width: '100%',
            }}
          >
            <FooterInfo />
          </div>
        )}
        <button
          data-testid="open_settings_button"
          className="button_menu_settings"
          onClick={handleClickSettings}
          style={{ width: 48, height: 48 }}
        >
          <MenuIcon fontSize="large" />
        </button>
      </div>

      <SpeakerSettingsModal handleCloseModal={handleCloseSpeakerSettingsModal} />
    </>
  );
};

export default Home;
