import React, { ChangeEvent, useState, MouseEvent } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { ISpeakerRole } from "./types";
import { cloneDeep } from "lodash";
import { SpeakerModalStage } from "../../../redux/store/IStore";
import { updateSpeakerSpeaker } from "../../../api/speakersService";
import Add from "@mui/icons-material/Add";
import CloseIcon from '@mui/icons-material/Close';
import { updateSpeakerModal } from "../../../redux/features/app/app";

const SpeakerModalEdit = () => {
    const dispatch = useAppDispatch();
    const selectedSpeaker = useAppSelector(store => store.app.speakerModal.selectedSpeaker);


    const [speakerLabel, setSpeakerLabel] = useState<string>(selectedSpeaker ? selectedSpeaker.name : "");
    const [speakerRole, setSpeakerRole] = useState<string>('');
    const [speakerRoles, setSpeakerRoles] = useState<ISpeakerRole[]>(selectedSpeaker ? selectedSpeaker.roles.map((role, index) => { return { label: role, default: index === 0 } }) : []);

    const updateSpeakerLabel = (event: ChangeEvent<HTMLInputElement>) => setSpeakerLabel(event.target.value)
    const updateSpeakerRole = (event: ChangeEvent<HTMLInputElement>) => setSpeakerRole(event.target.value)

    const addSpeakerRole = () => setSpeakerRoles(current => {
        if (speakerRole.length < 1) return current;

        current.push({ label: speakerRole, default: current.length === 0 })
        setSpeakerRole("");

        return current;
    })

    const changeDefaultRole = (index: number) => {
        const copy = cloneDeep(speakerRoles);
        copy.forEach((role, iterationIndex) => role.default = iterationIndex === index)
        setSpeakerRoles(copy)
    }

    const deleteRole = (event: MouseEvent<HTMLButtonElement>, index: number) => {
        event.preventDefault();
        event.stopPropagation();
        const copy = cloneDeep(speakerRoles);
        const isDeletedDefault = copy[index].default

        copy.splice(index, 1);
        if (isDeletedDefault && copy.length > 0) {
            copy[0].default = true
        }

        setSpeakerRoles(copy)
    }

    const cancelSpeakerCreate = () => dispatch(updateSpeakerModal({ modalStage: SpeakerModalStage.list }))

    const updateSpeakerWrapper = async () => {
        if (!selectedSpeaker) return;
        const roles = speakerRoles.sort((a, b) => Number(b.default) - Number(a.default)).map(role => role.label)
        try {
            await updateSpeakerSpeaker(selectedSpeaker.id, {
                name: speakerLabel,
                roles,
            });
            dispatch(updateSpeakerModal({ modalStage: SpeakerModalStage.list }))
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <div>
                <div id="speaker_modal_header_wrapper">
                    <p id="speaker_modal_header_title">Uredi govorca</p>
                </div>
                <input className="add_user_input" value={speakerLabel} placeholder="Vnesite ime..." onChange={updateSpeakerLabel} />
                <div id="speaker_modal_role_input_container">
                    <input className="add_user_input" id="speaker_modal_role_input" value={speakerRole} placeholder="Vnesite vlogo..." onChange={updateSpeakerRole} />
                    <button onClick={addSpeakerRole}>
                        <Add sx={{ color: "#2b8dff" }} />
                    </button>
                </div>
                <div id="speaker_roles_container">
                    {speakerRoles.map((iterationRole, index) => {
                        return (
                            <button onClick={() => changeDefaultRole(index)} className={`speaker_role${iterationRole.default ? " speaker_role_default" : ""}`}>
                                <button onClick={event => deleteRole(event, index)} className="speaker_role_delete">
                                    <div className={`speaker_role_delete_container${iterationRole.default ? " speaker_role_delete_container_default" : ""}`}>
                                        <CloseIcon sx={{ color: iterationRole.default ? "#2b8dff" : "#F2F2F2", width: 18, height: 18 }} />
                                    </div>
                                </button>
                                <p>{iterationRole.label}</p>
                            </button>
                        )
                    })}
                </div>
            </div>
            <div id="speaker_modal_footer">
                <button id="spekaer_modal_close_button" onClick={cancelSpeakerCreate}>NAZAJ</button>
                <button id="spekaer_modal_save_button" onClick={updateSpeakerWrapper}>SHRANI</button>
            </div>
        </div>
    )
}

export default SpeakerModalEdit;